.wrapper {
  position: relative;

  min-width: 33%;
  height: 100%;
}

.logo {
  position: absolute;

  width: 100%;
  height: 100%;
}
