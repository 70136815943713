.time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90rem;
  height: 15rem;
  margin-top: 3rem;

  font-size: 15rem;
}

.separator {
  flex-shrink: 0;
}

.timepart {
  flex-grow: 0;
  min-width: 15rem;
}

@media (max-width: 2400px) {
  .timepart {
    min-width: 10rem;
  }

  .time {
    width: 60rem;
    height: 10rem;
    margin-top: 2rem;

    font-size: 10rem;
  }
}

@media (max-width: 1920px) {
  .timepart {
    min-width: 7rem;
  }

  .time {
    width: 40rem;
    height: 7rem;
    margin-top: 1rem;

    font-size: 7rem;
  }
}

@media (max-width: 980px) {
  .timepart {
    min-width: 4rem;
  }

  .time {
    width: 20rem;
    height: 4rem;
    margin-top: 2rem;

    font-size: 4rem;
  }
}

@media (max-height: 480px) {
  .timepart {
    min-width: 5rem;
  }

  .time {
    height: 5rem;
    margin-top: 1rem;

    font-size: 5rem;
  }
}

@media (max-height: 320px) {
  .timepart {
    min-width: 2rem;
  }

  .time {
    width: 12rem;
    height: 3rem;

    font-size: 2rem;
  }
}
