body {
  margin: 0;
  padding: 0;

  color: white;
  font-family: "Century Gothic", sans-serif;

  background-color: #0c1220;
}

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;

  width: calc(1.5 * var(--vh, 1vh) * 100);
  min-width: 420px;
  max-width: 3840px;

  height: calc(100 * var(--vh, 1vh));
  min-height: 280px;
  max-height: 2510px;

  margin: auto;
  overflow: hidden;

  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 90%;
  background-size: cover;
  /* background-image: url(./images/wtuC6Jhzjkc83rMdNGD7.png); */
}

@media (max-aspect-ratio: 3/2) {
  .wrapper {
    width: 100%;

    min-width: 320px;
    min-height: 480px;

    background-size: cover;
  }
}
