.wrapper {
  z-index: 1;

  display: flex;

  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  min-width: 33%;
  height: 100%;
  padding: 4px 0;
}

.link {
  display: flex;
  justify-content: center;
  height: 100%;
}

.logo {
  width: auto;
  height: 100%;
}
