.wrapper {
  z-index: 1;

  display: flex;
  flex-direction: column;

  flex-grow: 1;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 5px 0;

  font-family: "Gabriola", sans-serif;
  text-align: center;
}

.text {
  margin: 0;

  font-size: 6rem;
}

.info {
  margin: 0;

  font-size: 4rem;
  color: white;
}

@media (max-width: 2400px) {
  .text {
    font-size: 4rem;
  }

  .info {
    font-size: 2rem;
  }
}

@media (max-width: 1920px) {
  .text {
    font-size: 3rem;
  }

  .info {
    font-size: 1.75rem;
  }
}

@media (max-width: 980px) {
  .text {
    font-size: 2rem;
  }

  .info {
    font-size: 1.5rem;
  }
}

@media (max-height: 320px) {
  .text { 
    font-size: 1rem;
  }

  .info {
    font-size: 0.5rem;
  }
}

.link {
  color: white;
}