.wrapper {
  position: relative;
  z-index: 1;

  height: 8%;

  background-color: rgba(0, 0, 0, 0.6);
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  padding: 1% 4%;
}

.border {
  position: absolute;
  top: -16%;

  width: 100%;
  height: 30%;

  background-image: url(../../images/frame_bottom.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.item {
  margin: 0 10px;

  color: white;
  font-size: 0.75rem;
  text-align: end;
}

.rules {
  color: white;
}

.discord {
  height: 2rem;

  color: white;
}

.logo {
  height: 100%;
}

.copyright {
  flex-grow: 1;
}

@media (max-width: 980px) {
  .item {
    font-size: 0.5rem;
  }

  .discord {
    height: 1rem;
  }
}

@media (max-height: 480px) {
  .item {
    font-size: 0.4rem;
  }
}
