.wrapper {
  position: relative;
  z-index: 2;

  height: 20%;
  min-height: 80px;

  background-color: rgba(0, 0, 0, 0.7);
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  height: 100%;
  padding: 2% 2% 3% 2%;
}

.border {
  position: absolute;
  bottom: -3%;

  width: 100%;
  height: 20%;

  background-image: url(../../images/frame_top.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 480px) {
  .content {
    padding-bottom: 4%;
  }
}

@media (max-aspect-ratio: 3/2) {
  .wrapper {

    max-height: calc(0.25 * 100vw);
  }
}
