@import-normalize;

@font-face {
  font-family: "Gabriola";
  src: url(./fonts/GABRIOLA.TTF) format('truetype');
}

@font-face {
  font-family: "Century Gothic";
  src: url(./fonts/GOTHIC.TTF) format('truetype');
}
