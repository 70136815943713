.wrapper {
  position: absolute;
  left: 50%;

  width: 100%;
  min-width: 420px;
  max-width: 3840px;
  height: 100%;
  min-height: 280px;
  max-height: 2510px;

  transform: translateX(-50%);
}

@media (max-aspect-ratio: 3/2) {
  .wrapper {
    width: calc(1.5 * var(--vh, 1vh) * 100);
    min-width: 320px;
    min-height: 480px;
  }
}

.branch {
  position: absolute;

  width: 100%;

  background-repeat: no-repeat;
  background-size: contain;

  animation: wavy ease-in-out infinite;
}

.branch__left {
  bottom: 0;
  left: -10px;

  height: 75%;

  background-image: url('../../images/fern_left.png');
  background-position: bottom left;
  transform-origin: 0% 100%;

  animation-duration: 10s;
  animation-delay: 0s;
}

.branch__middle {
  bottom: 10%;
  left: 0;

  height: 45%;

  background-image: url('../../images/fern_middle.png');
  background-position: 10%;
  transform-origin: bottom;

  animation-duration: 7s;
  animation-delay: 1s;
}

.branch__right {
  bottom: 0;
  left: 0;

  height: 30%;

  background-image: url('../../images/fern_right.png');
  background-position: 55% 100%;
  transform-origin: bottom;

  animation-duration: 5s;
  animation-delay: 0s;
}

.branch__behind {
  top: 40%;

  height: 30%;

  background-image: url('../../images/fern_behind.png');
  background-position: 96% 100%;
  transform-origin: bottom;

  animation-duration: 8s;
  animation-delay: 1s;
}

.stalker {
  bottom: 0;

  height: 68%;

  background-image: url('../../images/stalker.png');
  background-position: 102% 100%;
}

@keyframes wavy {
  0% {
    transform: skewX(0deg);
  }

  50% {
    transform: skewX(3deg);
  }

  100% {
    transform: skewX(0deg);
  }
}
