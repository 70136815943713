.wrapper {
  right: 0;
  left: 0;

  width: 0.5rem;
  height: 100%;

  margin: auto;
}

.wrapper > div {
  height: 100%;
}

.code {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 49%;
  z-index: 1;

  height: min-content;
  margin: auto;
  writing-mode: vertical-rl;

  font-size: 0.3rem;
  text-orientation: mixed;
  text-shadow: white 1px 0 2px;
}

@media (max-width: 480px) {
  .code {
    font-size: 0.2rem;
  }
}

@media (max-height: 480px) {
  .code {
    font-size: 0.2rem;
  }
}
